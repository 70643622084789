import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";
import Technologies from "./Technologies";

const StackSection: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Spacer size="extraLarge" />
      <HeadingBlock
        heading={putEmptyLineInsteadSpace(
          intl.formatMessage({ id: "moplay.stack_section.title" }),
          1
        )}
        pre={intl.formatMessage({ id: "moplay.stack_section.pre" })}
        paragraph={intl.formatMessage({ id: "moplay.stack_section.paragraph" })}
      >
        <Spacer size="large" />
        <Technologies />
        <Spacer size="large" />
      </HeadingBlock>
    </Container>
  );
};

export default StackSection;
