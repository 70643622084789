import React, { FC } from "react";

import {
  AppiumLogo,
  EslintLogo,
  FlowLogo,
  I18nLogo,
  JestLogo,
  LernaLogo,
  LottieLogo,
  ReactNativeLogo,
  ReactNativeWebLogo,
  ReactNavigationLogo,
  ReactRouterLogo,
  ReduxLogo,
  ReselectLogo,
  StorybookLogo
} from "../../assets/images/Images";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  align-items: center;
`;

const Technology = styled.li`
  width: 20%;
  text-align: center;
  padding: 0 ${rem(30)};
  margin: ${rem(20)} 0;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    width: 33.33%;
    padding: 0 ${rem(25)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    width: 50%;
  }
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: ${rem(80)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    max-height: ${rem(90)};
  }
`;

type TechnologyT = {
  title: string;
  logo: string;
};

const technologies: TechnologyT[] = [
  {
    title: "React Native",
    logo: ReactNativeLogo
  },
  {
    title: "Jest",
    logo: JestLogo
  },
  {
    title: "Storybook",
    logo: StorybookLogo
  },
  {
    title: "Lerna",
    logo: LernaLogo
  },
  {
    title: "Appium",
    logo: AppiumLogo
  },
  {
    title: "Lottie",
    logo: LottieLogo
  },
  {
    title: "Redux",
    logo: ReduxLogo
  },
  {
    title: "Flow",
    logo: FlowLogo
  },
  {
    title: "React Router",
    logo: ReactRouterLogo
  },
  {
    title: "Reselect",
    logo: ReselectLogo
  },
  {
    title: "Eslint",
    logo: EslintLogo
  },
  {
    title: "I18n",
    logo: I18nLogo
  },
  {
    title: "React Navigation",
    logo: ReactNavigationLogo
  },
  {
    title: "React Native Web",
    logo: ReactNativeWebLogo
  }
];

const Technologies: FC = () => {
  return (
    <Wrapper>
      {technologies.map((technology, index) => (
        <Technology key={index}>
          <Logo src={technology.logo} alt={technology.title} />
        </Technology>
      ))}
    </Wrapper>
  );
};

export default Technologies;
