import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  MoPlayBlock1,
  MoPlayBlock2,
  MoPlayBlock3
} from "../../assets/images/Images";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import HeadingBlock from "../general/HeadingBlock";
import ImagedBlocks, { ImagedBlockT } from "../general/ImagedBlocks";

const QualitySection: FC = () => {
  const intl = useIntl();

  const blocks: ImagedBlockT[] = [
    {
      text: intl.formatMessage({ id: "moplay.quality_section.block_1" }),
      image: MoPlayBlock1
    },
    {
      text: intl.formatMessage({ id: "moplay.quality_section.block_2" }),
      image: MoPlayBlock2
    },
    {
      text: intl.formatMessage({ id: "moplay.quality_section.block_3" }),
      image: MoPlayBlock3
    }
  ];

  return (
    <Container>
      <Spacer size="extraLarge" />
      <HeadingBlock
        heading={intl.formatMessage({ id: "moplay.quality_section.title" })}
        pre={intl.formatMessage({ id: "moplay.quality_section.pre" })}
        paragraph={intl.formatMessage({
          id: "moplay.quality_section.paragraph"
        })}
      >
        <Spacer size="large" />
        <ImagedBlocks blocks={blocks} />
      </HeadingBlock>
    </Container>
  );
};

export default QualitySection;
