import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";
import NumberedBlocks, { NumberedBlockT } from "../general/NumberedBlocks";

const ScrumSection: FC = () => {
  const intl = useIntl();

  const blocks: NumberedBlockT[] = [
    {
      text: intl.formatMessage({ id: "moplay.scrum_section.block_1" }),
      width: 3.5
    },
    {
      text: intl.formatMessage({ id: "moplay.scrum_section.block_2" }),
      width: 5
    },
    {
      text: intl.formatMessage({ id: "moplay.scrum_section.block_3" }),
      width: 3.5
    }
  ];

  return (
    <Container>
      <Spacer size="extraLarge" />
      <HeadingBlock
        heading={putEmptyLineInsteadSpace(
          intl.formatMessage({ id: "moplay.scrum_section.title" }),
          1
        )}
        pre={intl.formatMessage({ id: "moplay.scrum_section.pre" })}
        paragraph={intl.formatMessage({ id: "moplay.scrum_section.paragraph" })}
      />
      <Spacer mobileSize="medium" size="extraLarge" />
      <NumberedBlocks blocks={blocks} />
      <Spacer mobileSize="medium" size="extraLarge" />
    </Container>
  );
};

export default ScrumSection;
