import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { Appstore, MoPlayApp } from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper } from "../../components/GridComponents";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem, theme } from "../../styling/theme";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";
import { SIDEBAR_WIDTH } from "../SideBar";

const Wrapper = styled.div`
  background-color: ${({ theme }): string => theme.colors.backgroundGray};
  color: ${({ theme }): string => theme.colors.white};
  flex-direction: column;
  padding: ${rem(110)} 0;
  padding-left: ${rem(SIDEBAR_WIDTH)};
  margin-left: ${rem(-SIDEBAR_WIDTH)};
`;

const AppstoreImage = styled.img`
  width: ${rem(160)};
  margin-top: ${rem(20)};
`;

const AppScreen = styled.img`
  position: absolute;
  top: ${rem(-70)};
  left: ${rem(20)};
  width: 80%;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    position: relative;
    top: ${rem(-120)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    top: ${rem(-30)};
  }
`;

const WorldAppSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Spacer size="extraLarge" />
      <Wrapper>
        <Container>
          <GridWrapper>
            <Block width={7}>
              <AppScreen src={MoPlayApp} />
            </Block>
            <Block width={5}>
              <HeadingBlock
                withoutBlock
                heading={putEmptyLineInsteadSpace(
                  intl.formatMessage({ id: "moplay.worldapp_section.title" }),
                  2
                )}
                color={theme.colors.white}
                pre={putEmptyLineInsteadSpace(
                  intl.formatMessage({ id: "moplay.worldapp_section.pre" }),
                  4
                )}
                paragraph={intl.formatMessage({
                  id: "moplay.worldapp_section.paragraph"
                })}
              />
              <AppstoreImage src={Appstore} alt={"Appstore icon"} />
            </Block>
          </GridWrapper>
        </Container>
      </Wrapper>
      <Spacer size="extraLarge" />
    </>
  );
};

export default WorldAppSection;
