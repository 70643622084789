import React, { FC } from "react";

import Layout from "../../components/Layout";
import EnterSection from "../../partials/general/EnterSection";
import Introduction from "../../partials/general/Introduction";
import SuccessStoriesFooter from "../../partials/general/SuccessStoriesFooter";
import OptimizeSection from "../../partials/MoPlay/OptimizeSection";
import PlatfromSection from "../../partials/MoPlay/PlatfromSection";
import QualitySection from "../../partials/MoPlay/QualitySection";
import ScrumSection from "../../partials/MoPlay/ScrumSection";
import StackSection from "../../partials/MoPlay/StackSection";
import WorldAppSection from "../../partials/MoPlay/WorldAppSection";

const MoPlay: FC = () => {
  const ID = "moplay";

  return (
    <Layout
      helmetKey={ID}
      emptyMenu={true}
      blackMenu={true}
      lightColorVariant
      menuIconType="/success-stories"
      render={(): React.ReactChild => (
        <>
          <Introduction caseStudyId={ID} />
          <EnterSection caseStudyId={ID} />
          <StackSection />
          <ScrumSection />
          <OptimizeSection />
          <QualitySection />
          <PlatfromSection />
          <WorldAppSection />
          <SuccessStoriesFooter />
        </>
      )}
    />
  );
};

export default React.memo(MoPlay);
