import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { MoPlayGraph } from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Spacer from "../../components/Spacer";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";

const OptimizeSection: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Spacer size="extraLarge" />
      <GridWrapper>
        <Block>
          <HeadingBlock
            withoutBlock
            heading={putEmptyLineInsteadSpace(
              intl.formatMessage({ id: "moplay.optimize_section.title" }),
              2
            )}
            pre={intl.formatMessage({ id: "moplay.optimize_section.pre" })}
            paragraph={intl.formatMessage({
              id: "moplay.optimize_section.paragraph"
            })}
          />
          <Spacer size="medium" />
        </Block>
        <Block>
          <Image src={MoPlayGraph} alt="MoPlay graph" />
        </Block>
      </GridWrapper>
    </Container>
  );
};

export default OptimizeSection;
