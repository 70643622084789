import React, { FC } from "react";

import { Block, GridWrapper, Image } from "../../components/GridComponents";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";

const ImagedBlock = styled.div`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    margin-bottom: ${rem(50)};
    font-size: ${rem(16)};
  }
`;

const Heading = styled.h4`
  font-weight: 700;
  margin-bottom: 0;
`;

export type ImagedBlockT = {
  text: string;
  image: string;
};

type ImagedBlocksT = {
  blocks: ImagedBlockT[];
};

const ImagedBlocks: FC<ImagedBlocksT> = ({ blocks }) => {
  const EDGES = 30;

  return (
    <GridWrapper opositeMargin={EDGES}>
      {blocks.map((block, index) => {
        const splitedString = block.text.split("|");

        return (
          <Block
            key={index}
            paddingRight={EDGES}
            paddingLeft={EDGES}
            width={4}
            flexDirection="row"
          >
            <ImagedBlock>
              <Image src={block.image} alt={block.text} />
              <Heading>{splitedString[0]}</Heading>
              <p>{splitedString[1]}</p>
            </ImagedBlock>
          </Block>
        );
      })}
    </GridWrapper>
  );
};

export default ImagedBlocks;
